import React from "react";
import {Paper} from "@mui/material";
import {
  DataGridPro,
  gridClasses,
} from "@mui/x-data-grid-pro";
import {csCZ} from "@mui/x-data-grid/locales";
import Typography from "@mui/material/Typography";
import {bankColumns} from "../data/DatatableColumns";

const returnCashflow = (data, months) => {
  const numMonths = months.length;
  let sumOfCashflows = 0;
  let totalCashflow = 0;
  let cashflow = Array(numMonths).fill(0);

  data.report.receivedInvoiceList.forEach(invoice => {
    invoice.paymentList.forEach(payment => {
    const monthYear = convertDateToMMYY(payment.paymentDate);
    const monthIndex = months.indexOf(monthYear);
    const actualPayment = payment.paymentAmount || 0;
    if (monthIndex !== -1) {
      cashflow[monthIndex] -= actualPayment;
      sumOfCashflows -= actualPayment;
    }
    totalCashflow -= actualPayment;
    });
  });

  data.report.claimList.forEach(claim => {
    claim.paymentList.forEach(payment => {
    const monthYear = convertDateToMMYY(payment.paymentDate);
    const monthIndex = months.indexOf(monthYear);
    const actualPayment = payment.paymentAmount || 0;
    if (monthIndex !== -1) {
      cashflow[monthIndex] += actualPayment;
      sumOfCashflows += actualPayment;
    }
    totalCashflow += actualPayment;
    });
  });

  return {
    label: "Čistý finanční tok",
    cashflowArray: cashflow,
    cashflowsSum: sumOfCashflows,
    totalCashflow: totalCashflow
  };
};

const convertDateToMMYY = (dateString) => {
  const date = new Date(dateString.split('+')[0]);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear().toString().slice(-2);

  const formattedMonth = month.toString().padStart(2, '0');
  return `${formattedMonth}${year}`;
};

const compareDates = (date1, date2) => {
  const month1 = parseInt(date1.slice(0, 2), 10);
  const year1 = parseInt(date1.slice(2, 4), 10);
  const month2 = parseInt(date2.slice(0, 2), 10);
  const year2 = parseInt(date2.slice(2, 4), 10);

  if (year1 > year2) {
    return 1;
  } else if (year1 < year2) {
    return -1;
  } else {
    if (month1 > month2) {
      return 1;
    } else if (month1 < month2) {
      return -1;
    } else {
      return 0;
    }
  }
}

const calculateSum = (index, rowDefinitions, spendings, cashflowsSum) => {
  const accountBalanceIndex = rowDefinitions.findIndex(row => row.code === "accountBalance");
  const accountingDifferenceIndex = rowDefinitions.findIndex(row => row.code === "accountingDifference");
  const differenceIndex = rowDefinitions.findIndex(row => row.code === "difference");

  if (index === accountBalanceIndex) {
    return '-';
  } else if (index === accountingDifferenceIndex) {
    const differenceSum = spendings[differenceIndex].reduce((sum, value) => sum + value, 0);
    return Math.abs(Math.abs(cashflowsSum) - Math.abs(differenceSum));
  } else {
    return spendings[index].reduce((sum, value) => sum + value, 0);
  }
}

const calculateTotal = (index, rowDefinitions, total) => {
  const accountBalanceIndex = rowDefinitions.findIndex(row => row.code === "accountBalance");
  return index === accountBalanceIndex ? '-' : total[index];
}

const addRowIfNotExists = (rowDefinitions, code, name) => {
  const rowExists = rowDefinitions.some(row => row.code === code);
  if (!rowExists) {
    rowDefinitions.push({code, name});
  }
}

const getInitialAccountBalance = (companyDetail) => {
  switch (companyDetail.name) {
    case "NOHO Tangata s.r.o.":
      return 238887.34;
    default:
      return 0;
  }
}

const generateRows = (data, months, rowDefinitions) => {
  addRowIfNotExists(rowDefinitions, "difference", "Rozdíl");
  addRowIfNotExists(rowDefinitions, "accountBalance", "Stav účtu");
  addRowIfNotExists(rowDefinitions, "accountingDifference", "Účetní rozdíl");
  addRowIfNotExists(rowDefinitions, "uncategorized", "K zařazení");

  const numMonths = months.length;
  const spendings = rowDefinitions.map((definition) => {
    return Array(numMonths).fill(definition.code === "accountBalance" ? getInitialAccountBalance(data.report.companyDetail) : 0);
  });

  const total = rowDefinitions.map(() => 0);

  data.report.bankList.forEach(bankData => {
    const monthYear = convertDateToMMYY(bankData.expoDate);
    const monthIndex = months.indexOf(monthYear);
    const rowIndex = rowDefinitions.findIndex(row => bankData.movementType === row.code);
    if (compareDates(monthYear, months[0]) < 0) {
      for (let i = 0; i < numMonths; i++) {
        spendings[rowDefinitions.findIndex(row => row.code === "accountBalance")][i] += bankData.movementType === "prijem" ? bankData.amount : -bankData.amount;
      }
    }
    if (monthIndex !== -1 && rowIndex !== -1) {
      spendings[rowIndex][monthIndex] += bankData.amount;
      if (bankData.movementType === "vydej") {
        spendings[rowDefinitions.findIndex(row => row.code === "uncategorized")][monthIndex] -= bankData.relationList.length === 0 ? bankData.amount : 0;
        spendings[rowDefinitions.findIndex(row => row.code === "difference")][monthIndex] -= bankData.amount;
        for (let i = monthIndex; i < numMonths; i++) {
          spendings[rowDefinitions.findIndex(row => row.code === "accountBalance")][i] -= bankData.amount;
        }
      } else if (bankData.movementType === "prijem") {
        spendings[rowDefinitions.findIndex(row => row.code === "uncategorized")][monthIndex] += bankData.relationList.length === 0 ? bankData.amount : 0;
        spendings[rowDefinitions.findIndex(row => row.code === "difference")][monthIndex] += bankData.amount;
        for (let i = monthIndex; i < numMonths; i++) {
          spendings[rowDefinitions.findIndex(row => row.code === "accountBalance")][i] += bankData.amount;
        }
      }
    }
    if (bankData.movementType === "vydej") {
      total[rowDefinitions.findIndex(row => row.code === "difference")] -= bankData.amount;
      total[rowDefinitions.findIndex(row => row.code === "uncategorized")] -= bankData.relationList.length === 0 ? bankData.amount : 0;
    } else if (bankData.movementType === "prijem") {
      total[rowDefinitions.findIndex(row => row.code === "difference")] += bankData.amount;
      total[rowDefinitions.findIndex(row => row.code === "uncategorized")] += bankData.relationList.length === 0 ? bankData.amount : 0;
    }
    total[rowIndex] += bankData.amount;
  });

  spendings[rowDefinitions.findIndex(row => row.code === "accountingDifference")] = spendings[rowDefinitions.findIndex(row => row.code === "difference")].map((value, index) => value - spendings[rowDefinitions.findIndex(row => row.code === "difference")][index]);

  const {cashflowArray: cashflows, cashflowsSum, totalCashflow} = returnCashflow(data, months);

  for (let i = 0; i < numMonths; i++) {
    spendings[rowDefinitions.findIndex(row => row.code === "accountingDifference")][i] = Math.abs(Math.abs(spendings[rowDefinitions.findIndex(row => row.code === "difference")][i]) - Math.abs(cashflows[i]));
  }
  total[rowDefinitions.findIndex(row => row.code === "accountingDifference")] = Math.abs(Math.abs(total[rowDefinitions.findIndex(row => row.code === "difference")]) - Math.abs(totalCashflow));
  //sumNonMonthAccountingDifference = Math.abs(Math.abs(sumNonMonthDifferences) - Math.abs(cashflowsSum));

  return rowDefinitions.map((rowDef, index) => {
    const sum = calculateSum(index, rowDefinitions, spendings, cashflowsSum);
    const totalValue = calculateTotal(index, rowDefinitions, total);

    return {
      id: index,
      label: rowDef.name,
      spendings: spendings[index],
      sum: sum,
      total: totalValue,
    };
  });
};

const BankTable = ({data, months, rowDefinitions, reference, onRowsChange}) => {
  const rows = generateRows(data, months, rowDefinitions)
  onRowsChange && onRowsChange();

  const pinnedRows = {
    bottom: [
      rows[rowDefinitions.findIndex(row => row.code === "difference")],
      rows[rowDefinitions.findIndex(row => row.code === "accountBalance")],
      rows[rowDefinitions.findIndex(row => row.code === "accountingDifference")],
      rows[rowDefinitions.findIndex(row => row.code === "uncategorized")],
    ]
  };

  return (
    data.report && months.length > 0 && rowDefinitions.length > 0 &&
    <Paper ref={reference}>
      <Typography
        sx={{
          paddingLeft: 2,
          paddingTop: 2,
          paddingBottom: 2,
          fontWeight: 'bold',
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          fontSize: "0.85rem",
        }}
      >
        Banka
      </Typography>
      <DataGridPro
        rows={rows}
        hideFooter
        disableRowSelectionOnClick
        pinnedRows={pinnedRows}
        columns={bankColumns(months)}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pinnedColumns: {
            left: ['label'],
            right: ['sum', 'total'],
          },
        }}
        disableSelectionOnClick
        getCellClassName={(params) => {
          let classNames = '';

          // Add 'negative' class if the value is negative
          if (params.value < 0) {
            classNames += 'negative ';
          }

          // Add 'pinned-row-month' or 'pinned-row' class if it's a pinned row
          if (params.rowNode.type === 'pinnedRow') {
            if (params.colDef.headerClassName?.includes('month')) {
              classNames += 'pinned-row-month ';
            } else {
              classNames += 'pinned-row ';
            }
          }

          // Add 'month' class if the column is about "month"
          if (params.colDef.headerClassName?.includes('month')) {
            classNames += 'month ';
          }

          return classNames.trim(); // Remove extra spaces at the end
        }}
        sx={{
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .month': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            overflow: 'visible',
          },
          // Style for pinned rows
          [`.${gridClasses.cell}.pinned-row`]: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
          },
          [`.${gridClasses.cell}.pinned-row-month`]: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          // Style for negative numbers
          '& .MuiDataGrid-cell.negative': {
            color: 'red',
          },
        }}
      />
    </Paper>
  );
};

export default BankTable;