import React, {useState} from 'react';
import Sidebar from "../components/Sidebar";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Button, Paper} from "@mui/material";
import {userColumns} from "../data/DatatableColumns";
import Grid from "@mui/material/Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UserEdit from "../components/UserEdit";
import {csCZ} from "@mui/x-data-grid/locales";

export default function Users() {
  const [popUpMode, setPopUpMode] = useState("");
  const [popUpOpened, setPopUpOpened] = useState(false);

  const handleAddClick = () => {
    setPopUpMode("create");
    setPopUpOpened(true);
  }

  const handleEditClick = () => {
    setPopUpMode("edit");
    setPopUpOpened(true);
  }

  const handlePopUpClose = () => {
    setPopUpOpened(false);
  }

  return (
    <Sidebar>
      <Typography variant="h5" fontWeight="bold">Uživatelé</Typography>
      <Typography variant="h7"><Link to="/home" style={{textDecoration: 'none', color: 'black'}}>NOHO</Link> -> Uživatelé</Typography>
      <Paper sx={{marginTop: 2}}>
        <Grid container>
          <Grid size={6}>
            <Typography
              sx={{
                paddingLeft: 2,
                paddingTop: 1.7,
                paddingBottom: 1.7,
                fontWeight: 'bold',
                backgroundColor: 'white',
                position: 'sticky',
                left: 0,
                fontSize: "0.85rem",
                height: '45px',
              }}
            >
              Uživatelé
            </Typography>
          </Grid>
          <Grid size={6} alignItems="end" sx={{alignItems: "center", justifyContent: "end", display: "flex", paddingRight: 2}} >
            <Button variant="contained" onClick={handleAddClick} color="success" sx={{ fontSize: "14", padding: 0.5, paddingLeft: 2, paddingRight: 2}} startIcon={<AddCircleIcon/>}>
              Přidat
            </Button>
          </Grid>
        </Grid>
      <DataGridPro
        rows={[{id: 1, name: "Name1", email: "email1@example", surname: "Surname1"}, {id: 2,name: "name2", email: "email2@example", surname: "Surname2"}, {id: 3,name: "Name3", email: "email3@example", surname: "Surname3"}]}
        columns={userColumns(handleEditClick)}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          minHeight: "77vh",
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', overflow: 'visible'
          },
        }}
        hideFooter
      />
    </Paper>
      <UserEdit mode={popUpMode} data={null} selectedUser={null} opened={popUpOpened} handleClose={handlePopUpClose}/>
    </Sidebar>
  );
}
