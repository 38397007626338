import React, { useContext } from 'react';
import { GlobalDataContext } from "../../components/GlobalDataProvider";
import EntityDetail from "../../components/EntityDetail";
import Sidebar from "../../components/Sidebar";
import {useParams} from "react-router-dom";


const CentreDetail = () => {
  const {centresDetails} = useContext(GlobalDataContext);
  const {id} = useParams();

  return (
    <Sidebar>
      <EntityDetail
        entity={centresDetails ? centresDetails[id] : null}
        entityName="Střediska"
        apiPath="/costCenter"
        navPath="/centres"
        dateFrom={centresDetails ? centresDetails[id].data.report.costCenterDetail.timeInterval.from : null}
        dateTo={centresDetails ? centresDetails[id].data.report.costCenterDetail.timeInterval.to : null}
      />
    </Sidebar>
  );
};

export default CentreDetail;
