import Sidebar from "../components/Sidebar";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Paper} from "@mui/material";
import {tasksColumns} from "../data/DatatableColumns";
import Grid from "@mui/material/Grid2";
import {useContext, useState, useMemo} from "react";
import {GlobalDataContext} from "../components/GlobalDataProvider";
import {csCZ} from "@mui/x-data-grid/locales";

export default function Tasks() {
  const { companiesDetails } = useContext(GlobalDataContext);
  const [loading, setLoading] = useState(false);

  const rows = useMemo(() => {
    setLoading(true);
    if (!companiesDetails) {
      return [];
    }
    const result = [];
    Object.keys(companiesDetails).forEach((companyId) => {
      const company = companiesDetails[companyId];
      const { name } = company;
      const bankList = company.data.report.bankList;

      bankList.forEach((bank) => {
        if (bank.relationList.length === 0) {
          result.push({
            id: `${companyId}-${bank.code}`,
            company: name,
            code: bank.code,
            movementType: bank.movementType,
            amount: bank.amount,
            expoDate: bank.expoDate,
          });
        }
      });
    });
    setLoading(false);
    return result;
  }, [companiesDetails]);

  return (
    <Sidebar>
      <Typography variant="h5" fontWeight="bold">Úkoly</Typography>
      <Typography variant="h7"><Link to="/home" style={{textDecoration: 'none', color: 'black'}}>NOHO</Link> -> Úkoly</Typography>
      <Paper sx={{marginTop: 2}}>
        <Grid container>
          <Grid size={6}>
            <Typography
              sx={{
                paddingLeft: 2,
                paddingTop: 1.7,
                paddingBottom: 1.7,
                fontWeight: 'bold',
                backgroundColor: 'white',
                position: 'sticky',
                left: 0,
                fontSize: "0.85rem",
                height: '45px',
              }}
            >
              Úkoly
            </Typography>
          </Grid>
        </Grid>
        <DataGridPro
          rows={rows}
          columns={tasksColumns()}
          loading={loading}
          localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            height: '84vh',
            '&.MuiDataGrid-root': {
              borderRadius: '0px',
              borderLeft: 'none',
              borderRight: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold', overflow: 'visible'
            },
          }}
          hideFooter
        />
      </Paper>
    </Sidebar>
  );
}
