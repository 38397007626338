import { useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid2";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%', // Default width for both PC and mobile
  maxWidth: '70vh', // Maximum width for PC
  maxHeight: '90vh', // Adjust the height as needed
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: 24,
  bgcolor: 'background.paper',
  p: "0px 40px 30px 40px",
};


const UserEdit = ({mode, selectedUser, opened, data, handleClose, }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    if (opened) {
      mode === "edit" && fetchEditData();
    }else {
      clearForm();
    }
  }, [opened]);

  const fetchEditData = async () => {
    //TODO: fetch data from backend
    try {
      setName("Jmeno");
      setSurname("Prijmeni");
      setEmail("Email@example.com");
    } catch (error) {
      console.log(error);
      //TODO: add error popup
    }
  }

  const clearForm = () => {
    setName("");
    setSurname("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  const handleEdit = async () => {
    // TODO: Implement edit logic (and validation)
    console.log({
      name,
      surname,
      email,
      password,
      confirmPassword
    });
  }

  const handleCreate = async () => {
    // TODO: Implement create logic (and validation)
    console.log({
      name,
      surname,
      email,
      password,
      confirmPassword
    });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };


  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
          fontWeight: 'bold',
        }}>
          {mode === "edit" ? "Úprava uživatele" : "Vytváření nového uživatele"}{}
        </Typography>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid size={6}>
            Jméno:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Příjmení:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            E-mail:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Heslo:
            <br/>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              size="small"
              sx={{width: '100%'}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'Schovat heslo' : 'Zobrazit heslo'
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid size={6}>
            Heslo znovu:
            <br/>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              size="small"
              sx={{width: '100%'}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'Schovat heslo' : 'Zobrazit heslo'
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid size={6}>
          </Grid>
          <Grid container sx={{paddingTop: '20px'}} spacing={5}>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'end'}}>
              <Button variant="outlined" onClick={handleClose} startIcon={<CloseIcon/>}>
                Zrušit
              </Button>
            </Grid>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'start'}}>
              <Button variant="contained"   onClick={mode === 'create' ? handleCreate : handleEdit}
                      endIcon={<SaveIcon/>}>
                Uložit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UserEdit;
