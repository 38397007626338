import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, Divider, TextField, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import toast from "react-hot-toast";
import {useAuthHeader} from "react-auth-kit";
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%', // Default width for both PC and mobile
  maxWidth: '80vh', // Maximum width for PC
  maxHeight: '90vh', // Adjust the height as needed
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: 24,
  bgcolor: 'background.paper',
  p: "0px 40px 40px 40px",
};

const PhasesPopUp = ({opened, handleClose, selectedContract, setSelectedContract, setCompanyData, data, company}) => {
  const [renderKey, setRenderKey] = useState(0);
  const getAuthHeader = useAuthHeader();

  function formatAmount(number) {
    if (number === null || number === '' || isNaN(number)) {
      return '';
    }
    let roundedNumber = Math.round(number); // Round to the nearest whole number
    return roundedNumber.toLocaleString('cs-CZ'); // Format using locale without decimal places
  }

  function removeTimezone(dateString) {
    const plusIndex = dateString.indexOf('+');
    if (plusIndex !== -1) {
      return dateString.slice(0, plusIndex);
    }
    return dateString;
  }

  const calculateTotalScope = () => {
    let totalScope = 0;
    selectedContract.userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        totalScope += scope.value || 0;
      });
    });
    return totalScope;
  }

  const calculateTotalAssumption = () => {
    let totalAssumption = 0;
    selectedContract.userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        totalAssumption += assumption.value || 0;
      });
    });
    return totalAssumption;
  }

  const calculateActualPaymentMonthSum = (businessMonth, contractCode) => {
    return data
      .filter(
        (card) =>
          card.contractNumber === contractCode &&
          card.businessMonth === businessMonth
      )
      .reduce((sum, card) => sum + (card.actualPayment || 0), 0);
  };

  const calculateActualPaymentSum = (contractCode) => {
    return data //data = tableData[selectedCard]
      .filter(
        (card) =>
          card.contractNumber === contractCode
      )
      .reduce((sum, card) => sum + (card.actualPayment || 0), 0);
  };

  function formatDate(input) {
    if (input.length !== 4) {
      return input;
    }
    return `${input.slice(0, 2)}/${input.slice(2)}`;
  }

  const handleChangeAssumption = (event, month) => {

    const inputValue = event.target.value.replace(/\s+/g, '').replace(",", ".");

    const newAssumptionValue = parseFloat(parseFloat(inputValue).toFixed(2)) || 0;

    let updatedContract = JSON.parse(JSON.stringify(selectedContract));
    updatedContract.userValueList.forEach(userValue => {
      const assumptionIndex = userValue.businessMonthAssumptionList.findIndex(item => item.businessMonth === month);
      if (assumptionIndex !== -1) {
        userValue.businessMonthAssumptionList[assumptionIndex].value = newAssumptionValue;
      }
    });

    setSelectedContract(updatedContract);
  };

  const handleChangeScope = (event, month) => {
    const inputValue = event.target.value.replace(/\s+/g, '').replace(",", ".");
    const newScopeValue = parseFloat(parseFloat(inputValue).toFixed(2)) || 0;

    let updatedContract = JSON.parse(JSON.stringify(selectedContract));

    updatedContract.userValueList.forEach(userValue => {
      const scopeIndex = userValue.businessMonthScopeList.findIndex(item => item.businessMonth === month);
      if (scopeIndex !== -1) {
        userValue.businessMonthScopeList[scopeIndex].value = newScopeValue;
      }
    });

    setSelectedContract(updatedContract);
  };

  const handleBlur = (event) => {
    let value = event.target.value;
    value = value.replace(/\s+/g, '').replace(",", ".");

    if (!isNaN(value) && value !== '') {
      value = formatAmount(value);
      event.target.value = value;
    }
  };

  const handlePopUpSubmit = async () => {
    try {
      const token = getAuthHeader();

      const contract = company.data.report.contractList.find(
        (contract) => contract.code === selectedContract.code
      );

      await axios.post(
        "https://flexioverview.upi-group.cz/contract/update",
        {
          costCenterCode: contract.costCenter,
          contractCode: selectedContract.code,
          userValueList: selectedContract.userValueList
        },
        {headers: {Authorization: token}}
      );

      // Display success message
      toast.success("Informace byly aktualizovány!");
      handleClose();
      updateContract(selectedContract);
    } catch (error) {
      // Handle errors
      toast.error("Informace se nepodařilo aktualizovat!");
      console.error('Error updating time interval:', error);
    }
  };

  const handleClearAssumption = () => {
    const updatedContract = JSON.parse(JSON.stringify(selectedContract));
    updatedContract.userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        assumption.value = 0;
      });
    });
    setSelectedContract(updatedContract);
    setRenderKey(prevKey => prevKey + 1);  // trigger re-render
  };

  const handleClearScope = () => {
    const updatedContract = JSON.parse(JSON.stringify(selectedContract));
    updatedContract.userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        scope.value = 0;
      });
    });
    setSelectedContract(updatedContract);
    setRenderKey(prevKey => prevKey + 1);  // trigger re-render
  };

  const updateContract = (newContract) => {
    setCompanyData((prevData) => {
      const updatedContractList = prevData.report.contractList.map(contract => contract.code === newContract.code ? newContract : contract);

      return {
        ...prevData, report: {
          ...prevData.report, contractList: updatedContractList
        }
      };
    });
  };


  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          height: '100%',
          paddingTop: '40px',
          paddingBottom: '13px'
        }}>
          <Grid size={12}>
            <Typography variant="h6" sx={{marginBottom: '10px'}}>
              Informace o zakázce
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Kód zakázky:</strong> {selectedContract.code}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Číslo smlouvy:</strong> {selectedContract.contractNumber}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Platí od:</strong> {removeTimezone(selectedContract.startDate)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>
              <strong>Platí do:</strong> {removeTimezone(selectedContract.endDate)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography
              variant="body1"
              sx={{marginBottom: '10px', color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'}}
            >
              <strong>Aktuální plán: </strong>{' '}{formatAmount(selectedContract.costAssumption)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography
              variant="body1"
              sx={{marginBottom: '10px', color: selectedContract.scope >= 0 ? 'inherit' : 'red'}}
            >
              <strong>Aktuální smlouva: </strong>{' '}{formatAmount(selectedContract.scope)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{
              marginBottom: '10px',
              color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 || calculateTotalAssumption() >= 0 ? 'green' : 'red' ? 'green' : 'red'
            }}>
              <strong>Nový plán: </strong>{formatAmount(calculateTotalAssumption())}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{
              marginBottom: '10px',
              color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 || calculateTotalAssumption() >= 0 ? 'green' : 'red'
            }}>
              <strong>Nová smlouva: </strong>{formatAmount(calculateTotalScope())}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1" sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red'}}>
              <strong>Realizováno: </strong>{formatAmount(calculateActualPaymentSum(selectedContract.code))}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="body1"
                        sx={{color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'}}>
              <strong>Zbývá
                dočerpat: </strong>{formatAmount(calculateTotalScope() - calculateActualPaymentSum(selectedContract.code))}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Divider sx={{marginBottom: '20px', marginTop: '20px'}}/>
          </Grid>
          <Grid size={1.5}>
            <Typography variant="body2" fontWeight="bold">
              Fáze
            </Typography>
          </Grid>
          <Grid size={1.5}>
            <Typography variant="body2" fontWeight="bold">
              Měsíc
            </Typography>
          </Grid>
          <Grid size={3.25}>
            <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
              <Typography variant="body2" fontWeight="bold" marginLeft={1}>
                Plán
              </Typography>
              <DeleteIcon onClick={() => handleClearAssumption()}
                          sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
            </div>
          </Grid>
          <Grid size={3.25}>
            <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
              <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
                Smlouva
              </Typography>
              <DeleteIcon onClick={() => handleClearScope()}
                          sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
            </div>
          </Grid>
          <Grid size={2.5}>
            <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
              Realizováno
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {selectedContract.userValueList.map((userValue, index) => userValue.businessMonthScopeList.map((scope, idx) => {
            const assumption = userValue.businessMonthAssumptionList[idx];
            return (<React.Fragment key={`${index}-${idx}`}>
              <Grid size={1.5}>
                <Typography variant="body2">{userValue.label}</Typography>
              </Grid>
              <Grid size={1.5}>
                <Typography variant="body2">{formatDate(scope.businessMonth)}</Typography>
              </Grid>
              <Grid size={3.25}>
                <Box key={renderKey}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    defaultValue={formatAmount(assumption.value)}
                    onChange={(event) => handleChangeAssumption(event, scope.businessMonth)}
                    onBlur={(event) => handleBlur(event, scope.businessMonth)}
                  />
                </Box>
              </Grid>
              <Grid size={3.25}>
                <Box key={renderKey}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    defaultValue={formatAmount(scope.value)}
                    onChange={(event) => handleChangeScope(event, scope.businessMonth)}
                    onBlur={(event) => handleBlur(event, scope.businessMonth)}
                  />
                </Box>
              </Grid>
              <Grid size={2.5}>
                <Typography variant="body2" sx={{
                  color: (calculateActualPaymentMonthSum(
                    scope.businessMonth,
                    selectedContract.code,
                  )) >= 0 ? 'inherit' : 'red'
                }}>{formatAmount(calculateActualPaymentMonthSum(
                  scope.businessMonth,
                  selectedContract.code,
                ))}</Typography>
              </Grid>
            </React.Fragment>);
          }))}
        </Grid>
        <Grid container sx={{paddingTop: '20px'}} spacing={5}>
          <Grid size={6} sx={{display: 'flex', justifyContent: 'end'}}>
            <Button variant="outlined" onClick={handleClose} startIcon={<CloseIcon/>}>
              Zrušit
            </Button>
          </Grid>
          <Grid size={6} sx={{display: 'flex', justifyContent: 'start'}}>
            <Button variant="contained" onClick={handlePopUpSubmit} endIcon={<SaveIcon/>}>
              Uložit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PhasesPopUp;
